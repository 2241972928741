import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useStore = create((set) => ({
  counter: 0,
  increaseCounter: () => set((state) => ({ counter: state.counter + 1 })),
  resetCounter: () => set({ counter: 0 }),
  updateCounter: (newCounter) => set({ counter: newCounter }),
}));

export const usePersonStore = create(
  persist(
    (set) => ({
      uid: "",
      email: "",
      emailVerified: false,
      displayName: "",
      photoURL: "",
      updateUid: (uid) => set(() => ({ uid: uid })),
      updateEmail: (email) => set(() => ({ email: email })),
      updateEmailVerified: (emailVerified) =>
        set(() => ({ emailVerified: emailVerified })),
      updateDisplayName: (displayName) =>
        set(() => ({ displayName: displayName })),
      updatePhotoURL: (photoURL) => set(() => ({ photoURL: photoURL })),
    }),
    {
      name: "user",
    }
  )
);
//{"uid":"Xu1PsEm1HLUZQdLhHP1Ir1gmv4A3","email":"asd@gmail.com","emailVerified":false,"isAnonymous":false,"providerData":[{"providerId":"password","uid":"asd@gmail.com","displayName":null,"email":"asd@gmail.com","phoneNumber":null,"photoURL":null}],"stsTokenManager":{"refreshToken":"AMf-vBzTsn9aF6PA2oiaPy4CtM09-hDwd1syiofmZ5vlYFj9x0gPYN6h2Su3MCSSIJUUtGFB838myezO3tD5-JKb7kCTraSIzPBNdiXiawE5shTk2cXMffW1z_cmdL61oz2NqH1kdsKrygNqhUvsy-VDf0N-b4kgOnoM48gpVXktSXlmpdMz-GylCDCw8KWJh0n8CV0-DPrY","accessToken":"eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2MDI3MTI2ODJkZjk5Y2ZiODkxYWEwMzdkNzNiY2M2YTM5NzAwODQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdmQtY3JtLTRjOGM2IiwiYXVkIjoidmQtY3JtLTRjOGM2IiwiYXV0aF90aW1lIjoxNzE0NzkxNTAzLCJ1c2VyX2lkIjoiWHUxUHNFbTFITFVaUWRMaEhQMUlyMWdtdjRBMyIsInN1YiI6Ilh1MVBzRW0xSExVWlFkTGhIUDFJcjFnbXY0QTMiLCJpYXQiOjE3MTQ3OTE1MDMsImV4cCI6MTcxNDc5NTEwMywiZW1haWwiOiJhc2RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImFzZEBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.Fd9TJ1sb4RNhp1sxetLQ7ctm4qwS4mNVW3m_zNfvHHzMkXvKCfnMzKDNu2UnkBuXOyNKdQnK6biMkRMERz0a0m55FGX8fc27jwk_eaElvq-e_kieA67_IW2-7L_y0v8CHI5rS3cSCDS8aJd7OlGdDS6M2Qke_p_SigMrXyXCCxLLNNjsKWHUvNFRIdgx_OypxiZ1qiMDA3a6A5esIGXk3JjJJTHERZsl5zK4cngy26tCF3dpeowGQZNeYxj3l7tYrr1pfR3scqz21YzacgPfyz4leSMlcpafLJnvifd8NAk4QK8DWUpV5PsSCX0-CGB7pupzVT4Are_jlqBJ7thM9g","expirationTime":1714795103242},"createdAt":"1714791503049","lastLoginAt":"1714791503049","apiKey":"AIzaSyAgOWGDXs7iVqbn4iY9B4Pf4rbfJqYI9bY","appName":"[DEFAULT]"}
