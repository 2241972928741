import "../styling/login.css";
import { useState, useEffect } from "react";
import { ReactComponent as Password } from "../assets/images/icons/password.svg";
import { ReactComponent as Person } from "../assets/images/icons/person.svg";
import { useNavigate } from "react-router-dom";
import { usePersonStore } from "../config/store";
import { Link } from "react-router-dom";
import { isValidEmail } from "../utils/passwordChecks";
//Firebase
import { auth } from "../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export default function Login() {
  const [emailInput, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mainError, setMainError] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [responseError, setresponseError] = useState("");

  const navigate = useNavigate();

  //Zustand
  const uid = usePersonStore((state) => state.uid);
  const displayName = usePersonStore((state) => state.displayName);
  const updateUid = usePersonStore((state) => state.updateUid);
  const updateEmail = usePersonStore((state) => state.updateEmail);
  const updateEmailVerified = usePersonStore(
    (state) => state.updateEmailVerified
  );
  const updateDisplayName = usePersonStore((state) => state.updateDisplayName);
  const updatePhotoURL = usePersonStore((state) => state.updatePhotoURL);

  useEffect(() => {
    if (uid != "" && displayName === null) {
      navigate("/additionalInformation");
    } else if (uid != "") {
      navigate("/dashboard");
    }
  }, []);

  const loginUser = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    if (password?.trim() === "" || emailInput?.trim() === "") {
      //any of fields is empty
      setMainError("Please, fill out both fields");
      setButtonDisabled(false);
    } else if (!isValidEmail(emailInput)) {
      console.error("BAD EMAIL");
      setMainError("Please, fix email");
      setButtonDisabled(false);
    } else {
      setresponseError("");
      setMainError(false);
      signInWithEmailAndPassword(auth, emailInput, password)
        .then((userCredential) => {
          //Logic if user is created
          updateUid(userCredential.user.uid);
          updateEmail(userCredential.user.email);
          updateEmailVerified(userCredential.user.emailVerified);
          updateDisplayName(userCredential.user.displayName);
          updatePhotoURL(userCredential.user.photoURL);

          //TODO Create a logic to update username later
          // updateProfile(userCredential.user, {
          //   displayName: userInfo.name,
          // });
          navigate("/dashboard");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.massage;
          console.log(errorCode);
          if (error.code === "auth/invalid-credential") {
            setresponseError("Wrong email or password");
          } else if (error.code === "auth/invalid-email") {
            setresponseError("Incorrect email");
          } else {
            setresponseError(
              "Unknown error. Email us Serhii@vd.agency if the issue persists"
            );
          }
          console.error(error);
          setTimeout(() => {
            setButtonDisabled(false);
          }, "1000");
        });
    }
  };

  return (
    <div className="registerMainDiv">
      <h1 className="loginPageH1">Login</h1>
      <div className="loginMainContainer">
        {/* EMAIL INPUT */}
        <div className="containerInput">
          <div className="entryArea">
            <input
              name="email"
              type="text"
              className="customInput"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={emailInput}
            />
            <div className="labelLine">Email</div>
            <Person
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>

        {/* PASSWORD INPUT */}
        <div className="containerInput">
          <div className="entryArea">
            <input
              name="password"
              type="text"
              className="customInput"
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <div className="labelLine">Password</div>
            <Password
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>
        <Link to={"/resetPassword"} className="forgotPasswordLink">
          Forgot password?
        </Link>
        <div className="loginPageGridContainer">
          <button
            className="submitButton loginButtonSubmit"
            onClick={loginUser}
            disabled={isButtonDisabled}
          >
            Login
          </button>
        </div>
        <p className="smallError">{mainError}</p>
        <p className="smallError">{responseError}</p>
        <p className="dontHaveAccount">
          New?{" "}
          <Link className="dontHaveAccount" to="/register">
            Register!
          </Link>
        </p>
      </div>
    </div>
  );
}
