import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { usePersonStore } from "../config/store";

const PrivateRoute = ({ children }) => {
  const uid = usePersonStore((state) => state.uid);
  const displayName = usePersonStore((state) => state.displayName);

  const isLoggedIn = uid != "" ? true : false;

  if (isLoggedIn && displayName === null) {
    return <Navigate to="/additionalInformation" replace />;
  }

  return isLoggedIn ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
