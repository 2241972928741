import React, { useEffect } from "react";
import { auth, googleProvider } from "../config/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePersonStore } from "../config/store";
import { Link } from "react-router-dom";

//Utils
import isPinCorrect from "../utils/isPinCorrect";
import {
  symbolsChecks,
  upperLower,
  numberCheck,
  specialCharacterChecks,
  arePasswordsIdentical,
  isValidEmail,
} from "../utils/passwordChecks";

//Assets
import CheckPlusText from "../components/CheckPlusText";
import Input from "../components/Input";
import { ReactComponent as Check } from "../assets/images/icons/check.svg";
import { ReactComponent as Password } from "../assets/images/icons/password.svg";
import { ReactComponent as Person } from "../assets/images/icons/person.svg";

//Styling
import "../styling/register.css";
//TODO Export that button later to external function
import "../styling/input.css";

export default function Register() {
  const [emailInput, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [secretPin, setSecretPin] = useState("");
  const [pinError, setPinError] = useState(false);
  //maybe delete state object beyond
  const [passwordCheckQuality, setPasswordCheckQuality] = useState({
    symbols: false,
    upperLower: false,
    lettersNumbers: false,
    specialCharacter: false,
    match: false,
  });
  const [mainError, setMainError] = useState("");
  const [responseError, setresponseError] = useState("");

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const navigate = useNavigate();

  //Zustand
  const uid = usePersonStore((state) => state.uid);
  const email = usePersonStore((state) => state.email);
  const emailVerified = usePersonStore((state) => state.emailVerified);
  const displayName = usePersonStore((state) => state.displayName);
  const photoURL = usePersonStore((state) => state.photoURL);
  const updateUid = usePersonStore((state) => state.updateUid);
  const updateEmail = usePersonStore((state) => state.updateEmail);
  const updateEmailVerified = usePersonStore(
    (state) => state.updateEmailVerified
  );
  const updateDisplayName = usePersonStore((state) => state.updateDisplayName);
  const updatePhotoURL = usePersonStore((state) => state.updatePhotoURL);

  useEffect(() => {
    if (uid != "" && displayName === null) {
      navigate("/additionalInformation");
    } else if (uid != "") {
      navigate("/dashboard");
    }
  }, []);

  //TODO maybe will need to persist user as auth.currentUser somewhere.
  const createUser = (e) => {
    e.preventDefault();

    if (isPinCorrect(secretPin)) {
      setPinError(false);
      if (
        !symbolsChecks(password) ||
        !upperLower(password) ||
        !numberCheck(password) ||
        !specialCharacterChecks(password) ||
        !arePasswordsIdentical(password, passwordConfirm)
      ) {
        setMainError("Please, fix the password");
      } else if (!isValidEmail(emailInput)) {
        console.error("BAD EMAIL");
        setMainError("Please, fix email");
      } else {
        setButtonDisabled(true);
        setresponseError("");
        setMainError(false);
        createUserWithEmailAndPassword(auth, emailInput, password)
          .then((userCredential) => {
            //Logic if user is created
            updateUid(userCredential.user.uid);
            updateEmail(userCredential.user.email);
            updateEmailVerified(userCredential.user.emailVerified);
            updateDisplayName(userCredential.user.displayName);
            updatePhotoURL(userCredential.user.photoURL);

            //TODO Create a logic to update username later
            // updateProfile(userCredential.user, {
            //   displayName: userInfo.name,
            // });
            navigate("/additionalInformation");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.massage;
            console.log(errorCode);
            if (
              error.code === "auth/email-already-in-use" ||
              "auth/email-already-exists"
            ) {
              setresponseError("This email is already in use");
            } else if (error.code === "auth/invalid-email") {
              setresponseError("Incorrect email");
            } else {
              setresponseError(
                "Unknown error. Email us Serhii@vd.agency if the issue persists"
              );
            }
            console.error(error);
            setTimeout(() => {
              setButtonDisabled(false);
            }, "1000");
          });
      }
    } else {
      setPinError(true);
    }
  };

  //TODO Remove login with google
  const loginWithGoogle = (e) => {
    e.preventDefault();
    signInWithPopup(auth, googleProvider)
      .then((userCredential) => {
        //Logic if user is created
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.massage;
        console.error(error);
      });
  };

  // TODO maybe, add logic to force realtors to confirm email before letting them access CRM?
  return (
    <div className="registerMainDiv">
      <h1 className="registerPageH1">Register</h1>
      <div className="registerMainContainer">
        {/* CHECK EMAIL INPUT IF IT'S EMAIL */}
        {/* EMAIL INPUT */}
        <div className="containerInput">
          <div className="entryArea">
            <input
              type="text"
              className="customInput"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={emailInput}
            />
            <div className="labelLine">Email</div>
            <Person
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>

        {/* PASSWORD INPUT */}
        <div className="containerInput">
          <div className="entryArea">
            <input
              type="text"
              className="customInput"
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
            <div className="labelLine">Password</div>
            <Password
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>

        {/* CHECKBOXES PASSWORD CHECK */}
        <div>
          <CheckPlusText
            text={"At least 12 symbols"}
            color={symbolsChecks(password) ? "#3CD253" : "#FF693A"}
            marginTop={"16px"}
          />
          <CheckPlusText
            text={"At least 1 uppercase and 1 lowercase"}
            color={upperLower(password) ? "#3CD253" : "#FF693A"}
            marginTop={"8px"}
          />
          <CheckPlusText
            text={"Mix of letters and numbers"}
            color={numberCheck(password) ? "#3CD253" : "#FF693A"}
            marginTop={"8px"}
          />
          <CheckPlusText
            text={"At least 1 special character e.g. , ! @ # ?"}
            color={specialCharacterChecks(password) ? "#3CD253" : "#FF693A"}
            marginTop={"8px"}
          />
        </div>

        {/* CONFIRM PASSWORD CHECK */}
        <div className="containerInput">
          <div className="entryArea">
            <input
              type="text"
              className="customInput"
              required
              onChange={(e) => setPasswordConfirm(e.target.value)}
              value={passwordConfirm}
            />
            <div className="labelLine">Confirm password</div>
            <Password
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>

        {/* CHECK FOR PASSWORD CONFIRM */}
        <CheckPlusText
          style={{
            marginTop: "0px",
            marginTop: "4px",
            fontSize: "14px",
          }}
          text={
            arePasswordsIdentical(password, passwordConfirm)
              ? "Passwords match!"
              : "Passwords don't match"
          }
          color={
            arePasswordsIdentical(password, passwordConfirm)
              ? "#3CD253"
              : "#FF693A"
          }
          marginTop={"8px"}
          //   isDisplayed={arePasswordsIdentical(password, passwordConfirm)}
        />

        <div className="containerInput">
          <div className="entryArea">
            <input
              type="text"
              className="customInput"
              required
              onChange={(e) => setSecretPin(e.target.value)}
              value={secretPin}
            />
            <div className="labelLine">Pin</div>
            <Password
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>
        <p className="inputDescription">
          Special pin provided from owner to have access
        </p>

        <p className="smallError" style={{ textAlign: "left" }}>
          {pinError ? "Incorrect pin" : ""}
        </p>

        <div className="registerPageGridContainer">
          <button
            className="submitButton registerButtonSubmit"
            onClick={createUser}
          >
            Register
          </button>
          {/* TODO create terms of use popup/page */}
          <p className="registerAgreeTerms">
            By pressing "Register" you agree with our terms of use
          </p>

          <p className="haveAnAccount">
            Already have an account? <Link to="/login">Login!</Link>
          </p>
        </div>
        <p className="smallError">{mainError}</p>
        <p className="smallError">{responseError}</p>
      </div>
    </div>
  );
}

//{"uid":"Xu1PsEm1HLUZQdLhHP1Ir1gmv4A3","email":"asd@gmail.com","emailVerified":false,"isAnonymous":false,"providerData":[{"providerId":"password","uid":"asd@gmail.com","displayName":null,"email":"asd@gmail.com","phoneNumber":null,"photoURL":null}],"stsTokenManager":{"refreshToken":"AMf-vBzTsn9aF6PA2oiaPy4CtM09-hDwd1syiofmZ5vlYFj9x0gPYN6h2Su3MCSSIJUUtGFB838myezO3tD5-JKb7kCTraSIzPBNdiXiawE5shTk2cXMffW1z_cmdL61oz2NqH1kdsKrygNqhUvsy-VDf0N-b4kgOnoM48gpVXktSXlmpdMz-GylCDCw8KWJh0n8CV0-DPrY","accessToken":"eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2MDI3MTI2ODJkZjk5Y2ZiODkxYWEwMzdkNzNiY2M2YTM5NzAwODQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdmQtY3JtLTRjOGM2IiwiYXVkIjoidmQtY3JtLTRjOGM2IiwiYXV0aF90aW1lIjoxNzE0NzkxNTAzLCJ1c2VyX2lkIjoiWHUxUHNFbTFITFVaUWRMaEhQMUlyMWdtdjRBMyIsInN1YiI6Ilh1MVBzRW0xSExVWlFkTGhIUDFJcjFnbXY0QTMiLCJpYXQiOjE3MTQ3OTE1MDMsImV4cCI6MTcxNDc5NTEwMywiZW1haWwiOiJhc2RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImFzZEBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.Fd9TJ1sb4RNhp1sxetLQ7ctm4qwS4mNVW3m_zNfvHHzMkXvKCfnMzKDNu2UnkBuXOyNKdQnK6biMkRMERz0a0m55FGX8fc27jwk_eaElvq-e_kieA67_IW2-7L_y0v8CHI5rS3cSCDS8aJd7OlGdDS6M2Qke_p_SigMrXyXCCxLLNNjsKWHUvNFRIdgx_OypxiZ1qiMDA3a6A5esIGXk3JjJJTHERZsl5zK4cngy26tCF3dpeowGQZNeYxj3l7tYrr1pfR3scqz21YzacgPfyz4leSMlcpafLJnvifd8NAk4QK8DWUpV5PsSCX0-CGB7pupzVT4Are_jlqBJ7thM9g","expirationTime":1714795103242},"createdAt":"1714791503049","lastLoginAt":"1714791503049","apiKey":"AIzaSyAgOWGDXs7iVqbn4iY9B4Pf4rbfJqYI9bY","appName":"[DEFAULT]"}
