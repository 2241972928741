import { ReactComponent as Check } from "../assets/images/icons/check.svg";
export default function checkPlusText(props) {
  let checkColor = "#FFFFFF";
  checkColor = props.color;
  return (
    <div
      style={{
        marginTop: props.marginTop,
        display: props.isDisplayed ? "none" : "block",
      }}
    >
      {/* CSV */}
      <Check
        fill={checkColor}
        style={{
          display: "inline-block",
          margin: "0px",
          marginBottom: "-5px",
          transition: "0.8s ease",
        }}
      />
      <p
        style={{
          display: "inline-block",
          fontSize: "16px",
          margin: "0px",
          marginLeft: "8px",
        }}
      >
        {props.text}
      </p>
    </div>
  );
}
