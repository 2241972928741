import "../styling/contactUs.css";
import React, { useState } from "react";

import { ReactComponent as Person } from "../assets/images/icons/person.svg";
import { ReactComponent as Mail } from "../assets/images/icons/mailSVG.svg";
import { ReactComponent as Phone } from "../assets/images/icons/phoneSVG.svg";
import { ReactComponent as Notes } from "../assets/images/icons/notesSVG.svg";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [messageStatus, setMessageStatus] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const sendMessage = async (e) => {
    e.preventDefault(); // Prevent form submission
    setButtonDisabled(true);

    // Replace with your bot token
    const botToken = "7482626879:AAHBzekCW1CgS_2rbcTSJeVw1MjLX7CQR2s";
    // Replace with the chat ID you got from Telegram API
    // const chatId = "346299485"; //Personal chat
    const chatId = "-1002269103985"; //Group chat

    // Construct the message you want to send
    const message = `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\nComments: ${notes}`;

    try {
      // Send the message to the Telegram bot
      const response = await fetch(
        `https://api.telegram.org/bot${botToken}/sendMessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        }
      );

      if (response.ok) {
        setMessageStatus("Message sent successfully!");
        console.log("Message sent:", message);
      } else {
        setMessageStatus("Failed to send message.");
        console.error("Failed to send message:", response.statusText);
      }
    } catch (error) {
      setMessageStatus("Error sending message.");
      console.error("Error:", error);
    }

    // Re-enable the button after 5 seconds
    setTimeout(() => {
      setButtonDisabled(false);
    }, 5000); // 5 seconds timeout
  };

  return (
    <div className="loginMainContainer">
      {/* NAME INPUT */}
      <div className="containerInput">
        <div className="entryArea">
          <input
            name="fullname"
            type="text"
            className="customInput"
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <div className="labelLine">Name</div>
          <Person className="inputIconSvg" fill={"rgba(246, 246, 246, 0.5)"} />
        </div>
      </div>

      {/* EMAIL INPUT */}
      <div className="containerInput">
        <div className="entryArea">
          <input
            name="email"
            type="text"
            className="customInput"
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <div className="labelLine">Email</div>
          <Mail className="inputIconSvg" fill={"rgba(246, 246, 246, 0.5)"} />
        </div>
      </div>

      {/* OR */}

      {/* PHONE INPUT */}
      <div className="containerInput">
        <div className="entryArea">
          <input
            name="tel"
            type="text"
            className="customInput"
            required
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <div className="labelLine">Phone number</div>
          <Phone className="inputIconSvg" fill={"rgba(246, 246, 246, 0.5)"} />
        </div>
      </div>

      {/* NOTES INPUT */}
      <div className="containerInput">
        <div className="entryArea">
          <input
            name="notes"
            type="text"
            className="customInput"
            required
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          />
          <div className="labelLine">Additional comments</div>
          <Notes className="inputIconSvg" fill={"rgba(246, 246, 246, 0.5)"} />
        </div>
      </div>

      <div className="contactUsButtonContainer">
        <button
          className="submitContactButton"
          onClick={sendMessage}
          disabled={isButtonDisabled}
        >
          Submit
        </button>
        <p className="contactUsDisclaimerP">
          By pressing “Done” you let us collect your information to give you the
          best service
        </p>
      </div>
      <p
        className="statusMessage"
        style={{
          color:
            messageStatus === "Message sent successfully!" ? "green" : "red",
        }}
      >
        {messageStatus}
      </p>
    </div>
  );
};

export default ContactForm;
