//TODO button to sign out
//TODO Add big check animation after user ckicks done. It will have a timer of 1-2 seconds and animation of exact same duration. Then will redirect to CRM

import { useNavigate } from "react-router-dom";
import { usePersonStore } from "../config/store";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { ReactComponent as Person } from "../assets/images/icons/person.svg";

//Firebase
import { auth } from "../config/firebase";
import { updateProfile, signOut } from "firebase/auth";

export default function AdditionalInformation() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mainError, setMainError] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [responseError, setresponseError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  //Zustand
  const uid = usePersonStore((state) => state.uid);
  const displayName = usePersonStore((state) => state.displayName);
  const updateUid = usePersonStore((state) => state.updateUid);
  const updateEmail = usePersonStore((state) => state.updateEmail);
  const updateEmailVerified = usePersonStore(
    (state) => state.updateEmailVerified
  );
  const updateDisplayName = usePersonStore((state) => state.updateDisplayName);
  const updatePhotoURL = usePersonStore((state) => state.updatePhotoURL);

  const navigate = useNavigate();

  useEffect(() => {
    if (uid === "") {
      navigate("/login");
    } else if (uid != "" && displayName != null) {
      navigate("/dashboard");
    }
  }, []);

  const addDisplayName = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    if (firstName?.trim() === "" || lastName?.trim() === "") {
      setMainError("Please, enter both fields");
      setButtonDisabled(false);
    } else {
      setresponseError("");
      setMainError(false);
      const fullName = firstName + " " + lastName;
      console.log(fullName);
      updateProfile(auth.currentUser, { displayName: fullName })
        .then(() => {
          //   Profile updated
          setSuccessMessage("All set!");
          console.log("Success");
          updateDisplayName(fullName);
          setTimeout(() => {
            navigate("/dashboard");
          }, "1500");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);

          console.error(error);
          setTimeout(() => {
            setButtonDisabled(false);
          }, "1000");
        });
    }
  };

  const signOutUser = (e) => {
    e.preventDefault();
    signOut(auth)
      .then(() => {
        console.log("You are signed out");
        updateUid("");
        updateEmail("");
        updateEmailVerified("");
        updateDisplayName("");
        updatePhotoURL("");
        navigate("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="registerMainDiv">
      <h1 className="additionalInfoPageH1">Additional Information</h1>
      <div className="loginMainContainer">
        {/* First name INPUT */}
        <div className="containerInput">
          <div className="entryArea">
            <input
              name="firstName"
              className="customInput"
              required
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
            <div className="labelLine">First name</div>
            <Person
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>

        {/* Last name INPUT */}
        <div className="containerInput">
          <div className="entryArea">
            <input
              name="lastName"
              className="customInput"
              required
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
            <div className="labelLine">First name</div>
            <Person
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>

        <button
          className="submitButton resetPasswordBtnSubmit"
          onClick={addDisplayName}
          disabled={isButtonDisabled}
        >
          Done
        </button>
        <p className="smallError">{mainError}</p>
        <p className="smallError">{responseError}</p>
        <p className="smallSuccessMessage">{successMessage}</p>
        <p className="middleText">OR</p>
        {/* SIGNOUT */}
        <button className="signOutBtn secondaryBtn" onClick={signOutUser}>
          Sign out
        </button>
      </div>
    </div>
  );
}
