import "../styling/heading.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="headerMain">
      <nav className="nav">
        <Link to={"/"} className="navbarLink">
          Landing page
        </Link>
        <ul>
          <li>
            <Link to={"/dashboard"} className="navbarLink">
              Dashboard
            </Link>
            {/* TODO: Uncommect below and test. It should use function below and add class Active to the current link. */}
            {/* <CustomLink to="dashboard">New Dash</CustomLink> */}
          </li>
          <li>
            <p>Serhii@vd.agency</p>
          </li>
          <li>
            <p>+1 (647) 287 7555</p>
          </li>
        </ul>
      </nav>
    </div>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  //Remove End:true If i need to check partial path. For example: /dashboard will be match, but /dashboard/serhii will not be match. To fix that
  //just remove end:true from the function below.
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive === to ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
