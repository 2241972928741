//import React from 'react'

import { Link } from "react-router-dom";
import "../styling/notFound.css";

export default function NotFound() {
  return (
    <div>
      <div className="notFoundMainContainer">
        <h1 className="notFoundH1">ARE YOU LOST?</h1>
        <h1 className="notFoundH1">404</h1>
        <Link to="/" className="notFoundLink">
          Home
        </Link>
      </div>
    </div>
  );
}
