export function symbolsChecks(password) {
  password.replace(/\s/g, "");
  if (password.length >= 12) {
    return true;
  } else {
    return false;
  }
}

export function upperLower(password) {
  const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");

  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
}

export function numberCheck(password) {
  const pattern = new RegExp("(?=.*\\d)");

  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
}

export function specialCharacterChecks(password) {
  const pattern = new RegExp("(?=.*[-+_!@#$%^&*.,?])");

  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
}

export function arePasswordsIdentical(password, confirmPassword) {
  return password?.trim() === confirmPassword?.trim();
}

export function isValidEmail(email) {
  if (email?.trim() === "") {
    return false;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

//"^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
