//Fonts
import "./assets/fonts/Satoshi-Black.ttf";
import "./assets/fonts/Satoshi-Bold.ttf";
import "./assets/fonts/Satoshi-Medium.ttf";
import "./assets/fonts/Satoshi-Light.ttf";
import "./assets/fonts/Satoshi-Regular.ttf";
import "./assets/fonts/Satoshi-Italic.ttf";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styling/addons.css";
// import "./styling/elements.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//Utils
import PrivateRoute from "./utils/PrivateRoute";

//Pages
import Landing from "./pages/Landing";
import CrmDashboard from "./pages/CrmDashboard";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import AdditionalInformation from "./pages/AdditionalInformation";

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <Landing />,
  //   errorElement: <NotFound />,
  // },
  // {
  //   path: "/dashboard",
  //   element: (
  //     <PrivateRoute>
  //       {" "}
  //       <CrmDashboard />{" "}
  //     </PrivateRoute>
  //   ),
  // },
  {
    path: "/",
    element: <App />, // App includes Navbar, so it will show on all pages
    errorElement: <NotFound />,
    children: [
      { path: "/", element: <Landing /> },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute>
            <CrmDashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/resetPassword",
        element: <ResetPassword />,
      },
      {
        path: "/additionalInformation",
        element: <AdditionalInformation />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
