import React from "react";
import { useState, useEffect } from "react";
import { useStore, usePersonStore } from "../config/store";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import "../styling/crm.css";

export default function CrmDashboard() {
  const [currentUser, setCurrentUser] = useState("");

  const counter = useStore((state) => state.counter);
  const increaseCounter = useStore((state) => state.increaseCounter);
  const resetCounter = useStore((state) => state.resetCounter);
  const updateCounter = useStore((state) => state.updateCounter);

  //Zustand
  const uid = usePersonStore((state) => state.uid);
  const email = usePersonStore((state) => state.email);
  const emailVerified = usePersonStore((state) => state.emailVerified);
  const displayName = usePersonStore((state) => state.displayName);
  const photoURL = usePersonStore((state) => state.photoURL);
  const updateUid = usePersonStore((state) => state.updateUid);
  const updateEmail = usePersonStore((state) => state.updateEmail);
  const updateEmailVerified = usePersonStore(
    (state) => state.updateEmailVerified
  );
  const updateDisplayName = usePersonStore((state) => state.updateDisplayName);
  const updatePhotoURL = usePersonStore((state) => state.updatePhotoURL);

  useEffect(() => {
    setCurrentUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  //TODO Add addtional check if user is logged in. If not => to login page

  console.log("uid: " + uid);
  console.log("displayName: " + displayName);
  console.log("--------------------------------------");

  const user = auth.currentUser;
  if (user !== null) {
    user.providerData.forEach((profile) => {
      console.log("Sign-in provider: " + profile.providerId);
      console.log("  Provider-specific UID: " + profile.uid);
      console.log("  Name: " + profile.displayName);
      console.log("  Email: " + profile.email);
      console.log("  Photo URL: " + profile.photoURL);
    });
  } else {
    console.log("NO USER");
  }

  const signOutUser = (e) => {
    e.preventDefault();
    signOut(auth)
      .then(() => {
        console.log("You are signed out");
        updateUid("");
        updateEmail("");
        updateEmailVerified("");
        updateDisplayName("");
        updatePhotoURL("");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div className="crmMainDiv">
        <h1>
          This is the dashboard AKA main page in CRM which is available only to
          logged in users
        </h1>
        <p>{currentUser.email}</p>
        <button onClick={increaseCounter}>Increase Counter</button>
        <button onClick={resetCounter}>Reset Counter</button>
        <p>{counter}</p>
        <input
          onChange={(e) => updateCounter(parseInt(e.target.value))}
          value={counter}
        />
        <p>Uid: {uid}</p>
        <p>Email: {email}</p>
        <p>emailVerified: {emailVerified}</p>
        <p>displayName: {displayName}</p>
        <p>photoURL: {photoURL}</p>
        <button className="element" onClick={signOutUser}>
          Sign out
        </button>
      </div>
    </div>
  );
}
