import { useNavigate } from "react-router-dom";
import { usePersonStore } from "../config/store";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { ReactComponent as Person } from "../assets/images/icons/person.svg";

//Firebase
import { auth } from "../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { isValidEmail } from "../utils/passwordChecks";

export default function ResetPassword() {
  const [emailInput, setEmail] = useState("");
  const [mainError, setMainError] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [responseError, setresponseError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const uid = usePersonStore((state) => state.uid);

  useEffect(() => {
    if (uid != "") {
      navigate("/dashboard");
    }
  }, []);

  const resetPassword = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    if (emailInput?.trim() === "") {
      setMainError("Please, enter your email");
      setButtonDisabled(false);
    } else if (!isValidEmail(emailInput)) {
      setMainError("Please, enter the valid email");
      setButtonDisabled(false);
    } else {
      setresponseError("");
      setMainError(false);
      sendPasswordResetEmail(auth, emailInput)
        .then(() => {
          //   Reset email is sent
          setSuccessMessage("Email sent!");
          setTimeout(() => {
            navigate("/login");
          }, "1500");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          if (error.code === "auth/invalid-credential") {
            setresponseError("Wrong email or password");
          } else if (error.code === "auth/invalid-email") {
            setresponseError("Incorrect email");
          } else {
            setresponseError(
              "Unknown error. Email us Serhii@vd.agency if the issue persists"
            );
          }
          console.error(error);
          setTimeout(() => {
            setButtonDisabled(false);
          }, "1000");
        });
    }
  };

  return (
    <div className="registerMainDiv">
      <h1 className="resetPasswordPageH1">Reset password</h1>
      <div className="loginMainContainer">
        {/* EMAIL INPUT */}
        <div className="containerInput">
          <div className="entryArea">
            <input
              name="email"
              className="customInput"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={emailInput}
            />
            <div className="labelLine">Email</div>
            <Person
              className="inputIconSvg"
              fill={"rgba(246, 246, 246, 0.5)"}
            />
          </div>
        </div>

        <button
          className="submitButton resetPasswordBtnSubmit"
          onClick={resetPassword}
          disabled={isButtonDisabled}
        >
          Send email
        </button>
        <p className="smallError">{mainError}</p>
        <p className="smallError">{responseError}</p>
        <p className="smallSuccessMessage">{successMessage}</p>
        <p className="dontHaveAccount">
          Changed your mind?{" "}
          <Link className="dontHaveAccount" to="/login">
            Login!
          </Link>
        </p>
      </div>
    </div>
  );
}
