import "../styling/landing.css";
import backgroundImage from "../assets/images/MainBackgroundImage.png";
import chartDBImg from "../assets/images/chartPreviewDB.png";
import dashboardVD from "../assets/images/DashboardVDAgency.png";
import statsImg from "../assets/images/icons/stats.png";
import reminderImg from "../assets/images/icons/reminder.png";
import handImg from "../assets/images/icons/hand.png";
import houseImg from "../assets/images/icons/house.png";
import phoneImg from "../assets/images/icons/phone.png";
import websiteVarietyMEG from "../assets/images/WebsiteVarietyMEG.png";
import websiteVarietyKitchens from "../assets/images/websiteVarietyKitchens.png";
import websiteVarietyMEGOld from "../assets/images/websiteVarietyMEGOld.png";
import websiteVarietyYorkDev from "../assets/images/websiteVarietyYorkDev.png";
import websiteVarietyAI from "../assets/images/website4mockup.png";
import variety1 from "../assets/images/icons/Variety1.png";
import variety2 from "../assets/images/icons/Variety2.png";
import variety3 from "../assets/images/icons/Variety3.png";
import variety4 from "../assets/images/icons/Variety4.png";
import crm from "../assets/images/icons/crm.png";
import wrench from "../assets/images/icons/wrench.png";
import google from "../assets/images/icons/google.png";
import license from "../assets/images/icons/license.png";
import speed from "../assets/images/icons/speed.png";
import transition from "../assets/images/icons/transition.png";
import ai from "../assets/images/icons/ai.png";
import macbook from "../assets/images/icons/macbook.png";
import iphone from "../assets/images/icons/iphone.png";
import backgroundQuares from "../assets/images/BackgroundQuares.png";
import logo from "../assets/images/icons/VDlogo.png";
import ContactForm from "../components/ContactForm.js";

import { useState } from "react";

export default function Landing() {
  const [activeImage, setActiveImage] = useState(0);
  const [activeStep, setActiveStep] = useState(1);

  const steps = [
    {
      number: 1,
      title: "Discovery Call",
      description:
        "We learn about your real estate goals and discuss if our CRM can fit your needs.",
    },
    {
      number: 2,
      title: "Building your unique solutions",
      description:
        "We design a high-converting website and develop a powerful CRM, both tailored for realtors",
    },
    {
      number: 3,
      title: "Review & Adjustments",
      description:
        "Perfecting the details. Refine website and CRM based on your feedback.",
    },
    {
      number: 4,
      title: "Launch, Transition & Support",
      description:
        "Transferring data from the current CRM you use and going live with continuous updates to keep everything running smoothly",
    },
  ];

  const images = [
    websiteVarietyMEG,
    websiteVarietyKitchens,
    websiteVarietyMEGOld,
    websiteVarietyAI,
    // websiteVarietyYorkDev,
  ];

  const handleButtonClick = (index) => {
    setActiveImage(index);
  };

  return (
    <div className="landing">
      <div className="welcomeSection">
        <h1 className="landingMainH1">
          WE BUILD THE <br />
          BEST WEBSITES
        </h1>
        <h2 className="landingMainH2">FOR REALTORS</h2>
        <div className="landingMainImg" />
      </div>
      <div className="landingPageMainContainer">
        <h1 className="landingSectionBoldTitle">WHO and WHAT</h1>
        <p className="landingSectionTwoP">
          Ever wonder who creates those stunning websites that keep users
          engaged? We are the experts behind the best of the best. Our unique,
          high-efficiency designs guide visitors seamlessly to take
          action—whether it's making a purchase or reaching your desired goal.
          If you're ready for a website that not only captivates but converts,
          we’re here to make it happen.
        </p>
        <h1
          className="landingSectionBoldTitle"
          style={{ margin: "112px 0 0 0" }}
        >
          FEATURES
        </h1>
        <h3
          className="landingMainH3"
          style={{ marginTop: "64px", marginBottom: "40px" }}
        >
          Finally convinient CRM
        </h3>
        {/* DATABASE EXAMPLE */}
        <div className="gridLayoutChartContainer">
          <img
            src={dashboardVD}
            alt="VD DataBase"
            className="landingChartDBImg"
          />
          <ul className="landingListChartDB">
            <li className="landingListChartDBElement">
              <img
                src={statsImg}
                alt="Chart DataBase"
                className="landingListChartDBIcon"
              />
              <p className="landingListChartDBText">
                Important charts and stats
              </p>
            </li>
            <li className="landingListChartDBElement">
              <img
                src={reminderImg}
                alt="Chart DataBase"
                className="landingListChartDBIcon"
              />
              <p className="landingListChartDBText">
                Only needed notifications and reminders
              </p>
            </li>
            <li className="landingListChartDBElement">
              <img
                src={handImg}
                alt="Chart DataBase"
                className="landingListChartDBIcon"
              />
              <p className="landingListChartDBText">
                Drag and drop leads workflow
              </p>
            </li>
            <li className="landingListChartDBElement">
              <img
                src={houseImg}
                alt="Chart DataBase"
                className="landingListChartDBIcon"
              />
              <p className="landingListChartDBText">
                View properties listed by your agency
              </p>
            </li>
            <li className="landingListChartDBElement">
              <img
                src={phoneImg}
                alt="Chart DataBase"
                className="landingListChartDBIcon"
              />
              <p className="landingListChartDBText">Make calls from desktop</p>
            </li>
          </ul>
        </div>
        {/* VARIETY OF DESIGNS */}
        <h3 className="landingMainH3">Variety of designs</h3>
        {/* TODO: Potentially add timer to change state of buttons/images every 5 sec */}
        <div className="gridLayoutVarietyContainer">
          <div>
            <ul className="landingListVariety">
              <li className="landingListVarietyElement">
                <div
                  onClick={() => handleButtonClick(0)}
                  className={`landingListVarietyButton ${
                    activeImage === 0 ? "active" : ""
                  }`}
                >
                  <img
                    src={variety1}
                    alt="Flower 1"
                    className="landingListVarietyIcon"
                  />
                  <p className="landingVarietyListP">minimalist</p>
                </div>
              </li>
              <li className="landingListVarietyElement">
                <div
                  onClick={() => handleButtonClick(1)}
                  className={`landingListVarietyButton ${
                    activeImage === 1 ? "active" : ""
                  }`}
                >
                  <img
                    src={variety2}
                    alt="Flower 2"
                    className="landingListVarietyIcon"
                  />
                  <p className="landingVarietyListP">modern</p>
                </div>
              </li>
              <li className="landingListVarietyElement">
                <div
                  onClick={() => handleButtonClick(2)}
                  className={`landingListVarietyButton ${
                    activeImage === 2 ? "active" : ""
                  }`}
                >
                  <img
                    src={variety3}
                    alt="Flower 3"
                    className="landingListVarietyIcon"
                  />
                  <p className="landingVarietyListP">classic</p>
                </div>
              </li>
              <li className="landingListVarietyElement">
                <div
                  onClick={() => handleButtonClick(3)}
                  className={`landingListVarietyButton ${
                    activeImage === 3 ? "active" : ""
                  }`}
                >
                  <img
                    src={variety4}
                    alt="Flower 4"
                    className="landingListVarietyIcon"
                  />
                  <p className="landingVarietyListP">more</p>
                </div>
              </li>
            </ul>
          </div>

          <div className="landingMockupImg">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`style-${index}`}
                className={`varietyImage ${
                  activeImage === index ? "active" : ""
                }`}
              />
            ))}
          </div>
        </div>
        <h3 className="landingThirdTitleH3">Including all must-have options</h3>
        <p className="landingFreeChargeP">free of charge</p>
        <div className="gridOptionsContainer">
          <div>
            <div className="gridOptionsItem">
              <img src={crm} alt="icon" className="landingListOptionsIcon" />
              <p className="landingListOptionsP">Most convinient CRM</p>
            </div>
            <div className="gridOptionsItem">
              <img src={wrench} alt="icon" className="landingListOptionsIcon" />
              <p className="landingListOptionsP">Full technical support</p>
            </div>
            <div className="gridOptionsItem">
              <img src={google} alt="icon" className="landingListOptionsIcon" />
              <p className="landingListOptionsP">
                Protected by Google Firebase
              </p>
            </div>
            <div className="gridOptionsItem">
              <img
                src={license}
                alt="icon"
                className="landingListOptionsIcon"
              />
              <p className="landingListOptionsP">All licences included</p>
            </div>
          </div>
          <div>
            <div className="gridOptionsItem">
              <img src={speed} alt="icon" className="landingListOptionsIcon" />
              <p className="landingListOptionsP">
                One of the fastest hostings in the world
              </p>
            </div>
            <div className="gridOptionsItem">
              <img
                src={transition}
                alt="icon"
                className="landingListOptionsIcon"
              />
              <p className="landingListOptionsP">
                Seamless transition from your current website
              </p>
            </div>
            <div className="gridOptionsItem">
              <img src={ai} alt="icon" className="landingListOptionsIcon" />
              <p className="landingListOptionsP">AI implementation</p>
            </div>
            <div className="gridOptionsItem">
              <img src={ai} alt="icon" className="landingListOptionsIcon" />
              <p className="landingListOptionsP">Our invented technologies</p>
            </div>
          </div>
        </div>
        <div className="landingComingSoon">Coming soon</div>
        <div>
          <div className="gridOptionsItem">
            <img src={iphone} alt="icon" className="landingListOptionsIcon" />
            <p className="landingListOptionsP">Mobile APP</p>
          </div>
          <div className="gridOptionsItem">
            <img src={macbook} alt="icon" className="landingListOptionsIcon" />
            <p className="landingListOptionsP">Make calls from Desktop</p>
          </div>
        </div>

        <h1
          className="landingSectionBoldTitle"
          style={{ marginTop: "120px", marginBottom: "40px" }}
        >
          BENEFITS OF A GOOD WEBSITE
        </h1>
        <div className="backgroundSquaresContainer">
          <img
            src={backgroundQuares}
            alt="Background Squares"
            className="backgroundSquaresImg"
          />
        </div>
        <div className="landingBenefitsConteiner">
          <div className="landingBenefitsLine">
            Attracts only real clients which reduces calling time
          </div>
          <div className="landingBenefitsDevider" />
          <div className="landingBenefitsLine">
            Don't lose clients due to long loading time
          </div>
          <div className="landingBenefitsDevider" />
          <div className="landingBenefitsLine">
            Make sure they can find what they look for
          </div>
          <div className="landingBenefitsDevider" />
          <div className="landingBenefitsLine">
            Appears higher in Google search
          </div>
          <div className="landingBenefitsDevider" />
          <div className="landingBenefitsLine">
            High brand value increases company status
          </div>
          <div className="landingBenefitsDevider" />
          <div className="landingBenefitsLine">Stand out from competitors</div>
          <div className="landingBenefitsDevider" />
        </div>
        <h1
          className="landingSectionBoldTitlePrice"
          style={{ marginTop: "120px", marginBottom: "40px" }}
        >
          PRICE
        </h1>
        <div className="priceSectionContainer">
          <p className="priceDescriptionP">
            We offer a single, all-inclusive package—no fluff, just everything
            you need. While our base product is fixed, we offer tailored
            adjustments for unique needs, which may affect the price. To keep
            entry costs low, we charge a small one-time fee, followed by
            affordable monthly payments to ensure your site stays modern,
            secure, and stable.
          </p>
          <div className="priceSectionConditionsContainer">
            <div className="priceSectionConditionsItemH">Monthly</div>
            <div
              className="priceSectionConditionsItemP"
              style={{ marginBottom: "24px" }}
            >
              Starts at $1,000
            </div>
            <div className="priceSectionConditionsItemH">
              One time development / Setup fee
            </div>
            <div className="priceSectionConditionsItemP">Starts at $3,000</div>
          </div>
        </div>
        <div className="progressContactSectionContainer">
          {/* Progress div */}
          <div className="progressSectionContainer">
            <div className="progress-container">
              <h1
                className="landingSectionBoldTitle"
                style={{ marginBottom: "40px", marginTop: "40px" }}
              >
                PROGRESS
              </h1>
              <div className="progress-line">
                {steps.map((step, index) => (
                  <div key={step.number} className="step-wrapper">
                    <div
                      // className={`progress-step ${
                      //   activeStep >= step.number ? "active" : ""
                      // }`}
                      // POTENTIAL FIX
                      className={`progress-step ${
                        activeStep === step.number
                          ? "active"
                          : activeStep > step.number
                          ? "completed"
                          : ""
                      }`}
                      // POTENTIAL FIX
                      onClick={() => setActiveStep(step.number)}
                    >
                      <div
                        className={`progress-circle ${
                          activeStep > step.number ? "completed" : ""
                        }`}
                      >
                        {activeStep > step.number ? (
                          <span>&#10003;</span> // Display checkmark for completed steps
                        ) : (
                          <span>{step.number}</span> // Display number for current and future steps
                        )}
                      </div>
                      <div className="step-content">
                        <div
                          className={`step-title ${
                            activeStep > step.number ? "completed" : ""
                          }`}
                        >
                          {step.title}
                        </div>
                        {activeStep === step.number && (
                          <div className="step-description">
                            {step.description}
                          </div>
                        )}
                      </div>
                    </div>

                    {index < steps.length - 1 && (
                      <div
                        className={`progress-line-connector ${
                          activeStep > step.number
                            ? "completed"
                            : activeStep === step.number
                            ? "active"
                            : ""
                        }`}
                        style={{
                          // height: index === steps.length - 2 ? "86px" : "130px",
                          height: index === steps.length - 2 ? "86px" : "130px",
                        }} // Adjust height for last line
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="contactSectionContainer">
            <h1
              className="landingSectionBoldTitle"
              // style={{ marginBottom: "40px", marginTop: "40px" }}
            >
              CONTACT US
            </h1>
            <div className="contactForm">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <img src={logo} alt="logo" className="footerLogo" />
        <div className="footerDisclaimerP">
          All rights reserved and owned by Vellkunio Digital Agency Inc. @2024
        </div>
      </div>
    </div>
  );
}
