import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAgOWGDXs7iVqbn4iY9B4Pf4rbfJqYI9bY",
  authDomain: "vd-crm-4c8c6.firebaseapp.com",
  projectId: "vd-crm-4c8c6",
  storageBucket: "vd-crm-4c8c6.appspot.com",
  messagingSenderId: "472774538497",
  appId: "1:472774538497:web:b41dd753b0dd17f266d700",
  measurementId: "G-WH7WQ0X2MY",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
